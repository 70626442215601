import React, { ReactElement, memo, useEffect, useState, useCallback } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CircularProgress, useTheme, Theme } from '@material-ui/core';
import { Download } from '@novozymes/components';
import { useRecoilValue } from 'recoil';
import getAtomState, { activeScenarioState } from 'state/atomState';
import { Scenario, TimeStep } from 'scenarios/ScenarioType';
import {
  phAdjustRefEnzyme,
  phAdjustNewEnzyme,
  dailyTotalsWithEnzymesState,
  liqEnzymeSavings,
  liqCalciumSavings as liqCalciumSavingsState,
  liqBaseSavings as liqBaseSavingsState,
  liqAcidSavings as liqAcidSavingsState,
  ionExchangeResinSavings,
  ixChemicalSavings,
  ionExchWasteWaterSavings,
  ionExchWaterSavings,
  ionExchSweetWaterSavings,
  ionExchangeAnChem,
  ionExchangeCatChem,
  ionExchangeAnWasteWater,
  ionExchangeCatWasteWater,
  ionExchangeAnWater,
  ionExchangeCatWater,
  ionExchangeCatResin,
  ionExchangeAnResin,
  ionExchangeCatSweetWater,
  ionExchangeAnSweetWater,
  ionExchangeCatTotal,
  ionExchangeAnTotal,
  reductionOfCalcium,
  reductionOfChemicals,
  totalIonExchangeReduction,
  refEnzymeModelOutput,
  newEnzymeModelOutput,
  currency,
} from 'state/getDerivedValues';
import calcSlurryDensity from 'calculation/utils/calcSlurryDensity';
import calcSteamConsumption from 'calculation/utils/calcSteamConsumption';
import DEChart from 'components/DEChart';
import SavingsChart, { SavingsChartEntry } from 'components/SavingsChart';
import logger from 'utils/logger';
import ButtonWithIcon from 'components/ButtonWithIcon';
import PDFDocument from './PDFDocument';
import GetGraphAsImage from './GetGraphAsImage';

const PDF_FILE_NAME = `Liquefaction_report_${Date.now()}.pdf`;

const DownloadOnRender = ({ onDownloaded }: { onDownloaded?: Function }): ReactElement => {
  useEffect(() => {
    // eslint-disable-next-line no-undef,no-unused-expressions
    document.getElementById('pdf_download')?.click();
    if (onDownloaded) {
      onDownloaded();
    }
  }, []);

  return (
    <div style={{ display: 'none' }} id="pdf_download">
      Download pdf
    </div>
  );
};

const DownloadLink = memo(
  ({
    theme,
    currentScenario,
    calculationResult,
    onDownloaded,
    DEChartImage,
    SavingsChartImage,
    activeCurrency,
  }: {
    linkText?: string;
    theme: Theme;
    currentScenario: Scenario;
    calculationResult: Record<string, any>;
    onDownloaded?: Function;
    DEChartImage: string;
    SavingsChartImage: string;
    activeCurrency: string;
  }): ReactElement => {
    return (
      <PDFDownloadLink
        document={
          <PDFDocument
            theme={theme}
            currentScenario={currentScenario}
            calculationResult={calculationResult}
            DEChartImage={DEChartImage}
            SavingsChartImage={SavingsChartImage}
            currency={activeCurrency}
          />
        }
        fileName={PDF_FILE_NAME}
        style={{ display: 'none' }}
      >
        {({ loading, url }) => {
          if (!loading && url) {
            const link = document.createElement('a');
            link.href = url;
            link.download = PDF_FILE_NAME;
            link.click();
            if (onDownloaded) {
              onDownloaded();
            }
          }
          return loading ? <CircularProgress color="primary" /> : null;
        }}
      </PDFDownloadLink>
    );
  }
);

const PDFDataWrapper = ({ linkText }: { linkText?: string }): ReactElement => {
  const theme = useTheme();

  const activeCurrency = useRecoilValue(currency);

  const [startDownload, setStartDownload] = useState(false);

  const currentScenario = useRecoilValue(activeScenarioState);
  const calciumPhAdj = { new: useRecoilValue(phAdjustNewEnzyme), ref: useRecoilValue(phAdjustRefEnzyme) };

  const { DSSlurryLiq, effects, plantCapacity, newEnzymeName, refEnzymeName } = currentScenario.data;

  const [DEChartImage, setDEChartImage] = useState('');
  const [SavingsChartImage, setSavingsChartImage] = useState('');

  const refModelPlotData = useRecoilValue(refEnzymeModelOutput);
  const newModelPlotData = useRecoilValue(newEnzymeModelOutput);

  const timeStep = useRecoilValue(getAtomState<TimeStep>('timeStep'));

  const DEGraphData =
    refModelPlotData && newModelPlotData && timeStep
      ? newModelPlotData.map((modelADataPoint, i) => ({
          time: i * timeStep,
          modelA: modelADataPoint,
          modelB: refModelPlotData[i],
        }))
      : [];

  const savingsData: SavingsChartEntry[] = [
    { savingType: 'Liquefaction enzyme', savings: useRecoilValue(liqEnzymeSavings) },
    { savingType: 'Calcium added', savings: useRecoilValue(liqCalciumSavingsState) },
    { savingType: 'Liquefaction base', savings: useRecoilValue(liqBaseSavingsState) },
    { savingType: 'Liquefaction acid', savings: useRecoilValue(liqAcidSavingsState) },
    { savingType: 'IX resins', savings: useRecoilValue(ionExchangeResinSavings) },
    { savingType: 'IX chemicals', savings: useRecoilValue(ixChemicalSavings) },
    { savingType: 'IX Waste water', savings: useRecoilValue(ionExchWasteWaterSavings) },
    { savingType: 'IX water', savings: useRecoilValue(ionExchWaterSavings) },
    { savingType: 'IX SW evaporation', savings: useRecoilValue(ionExchSweetWaterSavings) },
  ];

  useEffect(() => {
    if (startDownload) {
      GetGraphAsImage(DEChart, {
        data: DEGraphData,
        modelAName: newEnzymeName,
        modelBName: refEnzymeName,
        height: 700,
        width: 700,
        modelAColor: theme.palette.primary.main,
        modelBColor: theme.palette.secondary.main,
      })
        .then((res) => {
          setDEChartImage(res);
        })
        .catch((err) => {
          logger.error('Error while converting graph', { error: err.message });
        });
    }
  }, [startDownload, DEGraphData, newEnzymeName, refEnzymeName, theme]);

  useEffect(() => {
    if (startDownload) {
      GetGraphAsImage(SavingsChart, {
        data: savingsData,
        toolTipColor: theme.palette.primary.dark,
        barColor: theme.palette.primary.main,
        currency: activeCurrency,
        height: 700,
        width: 700,
      })
        .then((res) => {
          setSavingsChartImage(res);
        })
        .catch((err) => {
          logger.error('Error while converting graph', { error: err.message });
        });
    }
  }, [startDownload, savingsData]);

  const handleDownloadClick = useCallback(() => {
    setStartDownload(true);
  }, []);

  const onDownloadedCallback = useCallback(() => {
    setStartDownload(false);
  }, []);

  const starchSlurryDensity = calcSlurryDensity(DSSlurryLiq);
  const steamConsumption = calcSteamConsumption(effects);

  const dailyTotals = Math.round(useRecoilValue(dailyTotalsWithEnzymesState));
  const yearlyTotals = dailyTotals * 365;

  const savingsPerStarch = plantCapacity ? dailyTotals / plantCapacity : 0;

  const enzymeSavings = useRecoilValue(liqEnzymeSavings);
  const liqCalciumSavings = useRecoilValue(liqCalciumSavingsState);
  const liqBaseSavings = useRecoilValue(liqBaseSavingsState);
  const liqAcidSavings = useRecoilValue(liqAcidSavingsState);
  const ixResinSavings = useRecoilValue(ionExchangeResinSavings);
  const ixChemSavings = useRecoilValue(ixChemicalSavings);
  const ixWasteWaterSavings = useRecoilValue(ionExchWasteWaterSavings);
  const ixWaterSavings = useRecoilValue(ionExchWaterSavings);
  const ixSweetWaterSavings = useRecoilValue(ionExchSweetWaterSavings);

  const calciumReduction = useRecoilValue(reductionOfCalcium);
  const chemicalReduction = useRecoilValue(reductionOfChemicals);
  const totalReduction = useRecoilValue(totalIonExchangeReduction);

  const regenerationCosts = {
    cation: {
      chemical: useRecoilValue(ionExchangeCatChem),
      wasteWater: useRecoilValue(ionExchangeCatWasteWater),
      water: useRecoilValue(ionExchangeCatWater),
      resin: useRecoilValue(ionExchangeCatResin),
      sweetWaterEvaporation: useRecoilValue(ionExchangeCatSweetWater),
      total: useRecoilValue(ionExchangeCatTotal),
    },
    anion: {
      chemical: useRecoilValue(ionExchangeAnChem),
      wasteWater: useRecoilValue(ionExchangeAnWasteWater),
      water: useRecoilValue(ionExchangeAnWater),
      resin: useRecoilValue(ionExchangeAnResin),
      sweetWaterEvaporation: useRecoilValue(ionExchangeAnSweetWater),
      total: useRecoilValue(ionExchangeAnTotal),
    },
  };

  const calculationResult = {
    calciumPhAdj,
    starchSlurryDensity,
    steamConsumption,
    dailyTotals,
    yearlyTotals,
    savingsPerStarch,
    enzymeSavings,
    liqCalciumSavings,
    liqBaseSavings,
    liqAcidSavings,
    ixResinSavings,
    ixChemSavings,
    ixWasteWaterSavings,
    ixWaterSavings,
    ixSweetWaterSavings,
    regenerationCosts,
    calciumReduction,
    chemicalReduction,
    totalReduction,
  };

  if (!startDownload || !DEChartImage || !SavingsChartImage) {
    return (
      <ButtonWithIcon
        id="btn-export-pdf"
        icon={<Download />}
        title={linkText || 'Export results'}
        onClick={handleDownloadClick}
      />
    );
  }

  return (
    <DownloadLink
      onDownloaded={onDownloadedCallback}
      theme={theme}
      currentScenario={currentScenario}
      calculationResult={calculationResult}
      DEChartImage={DEChartImage}
      SavingsChartImage={SavingsChartImage}
      activeCurrency={activeCurrency}
    />
  );
};

export default PDFDataWrapper;
